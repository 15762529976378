import React from 'react';
import style from './TableSubscriptionAlert.module.scss';
import { useHistory } from 'react-router';
import { ROUTES } from '@constants/routes';

const TableSubscriptionAlert = ({
    type,
}: {
    type: 'patients' | 'presentations' | 'trg';
}) => {
    const history = useHistory();
    return (
        <div className={style.alert__container}>
            <div>
                <span className={style.alert__title}>
                    💬 История{' '}
                    {type === 'presentations'
                        ? 'презентаций'
                        : type === 'patients'
                        ? 'пациентов'
                        : 'расчетов'}
                </span>
                <span className={style.alert__subtitle}>
                    Доступны{' '}
                    {type === 'presentations'
                        ? 'презентации'
                        : type === 'patients'
                        ? 'пациенты'
                        : 'расчеты'}{' '}
                    текущего месяца. Чтобы получить доступ ко всем, приобретите
                    Pro подписку
                </span>
            </div>
            <div>
                <div
                    className={style.alert__button}
                    onClick={() => history.push(ROUTES.PROFILE.END)}
                >
                    Приобрести
                </div>
            </div>
        </div>
    );
};

export default TableSubscriptionAlert;
