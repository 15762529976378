class RouteFactory {
    private tmp_path: string[];

    constructor() {
        this.tmp_path = [];
    }

    get ROOT() {
        return this;
    }

    get PHOTOS() {
        this.tmp_path.push('photos');
        return this;
    }

    get PHOTO_COLLECTIONS() {
        this.tmp_path.push('photo-collections');
        return this;
    }

    get STORAGE() {
        this.tmp_path.push('storage');
        return this;
    }

    get FILE() {
        this.tmp_path.push('file');
        return this;
    }

    get EDIT() {
        this.tmp_path.push('edit');
        return this;
    }

    get ADD() {
        this.tmp_path.push('add');
        return this;
    }

    get FILL() {
        this.tmp_path.push('fill');
        return this;
    }

    get STORE() {
        this.tmp_path.push('store');
        return this;
    }

    get FROM_COLLECTION() {
        this.tmp_path.push('from-collection');
        return this;
    }

    get PROFILE() {
        this.tmp_path.push('profile');
        return this;
    }

    get SIGN_UP() {
        this.tmp_path.push('signup');
        return this;
    }

    get LOGIN() {
        this.tmp_path.push('login');
        return this;
    }

    get VERIFY() {
        this.tmp_path.push('verify');
        return this;
    }

    get RESET() {
        this.tmp_path.push('reset');
        return this;
    }

    get PAYMENT() {
        this.tmp_path.push('payment');
        return this;
    }

    get PATIENTS(): RouteFactory {
        this.tmp_path.push('patients');
        return this;
    }

    get PRESENTATIONS() {
        this.tmp_path.push('presentations');
        return this;
    }

    get START_FOLDER() {
        this.tmp_path.push('start-folder');
        return this;
    }

    $(value?: string) {
        this.tmp_path.push(value ?? 'undefined');
        return this;
    }

    get TRG() {
        this.tmp_path.push('trg');
        return this;
    }

    get SUPPORT() {
        this.tmp_path.push('support');
        return this;
    }

    get INFO() {
        this.tmp_path.push('info');
        return this;
    }

    get REPORT() {
        this.tmp_path.push('report');
        return this;
    }

    get END(): string {
        const path = '/' + this.tmp_path.join('/');
        this.tmp_path = [];
        return path;
    }

    toString() {
        const path = '/' + this.tmp_path.join('/');
        this.tmp_path = [];
        return path;
    }
}

export const ROUTES = new RouteFactory();
