export const paletteColors: string[] = [
    '#EF5350',
    '#EC407A',
    '#AB47BC',
    '#7E57C2',
    '#5C6BC0',
    '#42A5F5',
    '#29B6F6',
    '#26C6DA',
    '#26A69A',
    '#66BB6A',
    '#9CCC65',
    '#D4E157',
    '#FFEE58',
    '#FFCA28',
    '#FFA726',
    '#FF7043',
    '#F4511E',
    '#D62424',
    '#9B0009',
    '#640E0E',
    '#000000',
    '#505050',
    '#979797',
    '#CBCDCD',
    '#FFFFFF',
];
