import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './SliderSection.module.scss';
import Slide from '@components/verification/slide/Slide';
import { sliderData } from '@constants/sliderData';
import { classes } from '@utils/classNames';
import './SliderDots.scss';
import { id } from '@utils/utils';

const SliderSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 7000,
    };

    return (
        <div className={style.sliderSection__container}>
            <div className={style.slider__container}>
                <div>
                    <span className={style.sliderSection__title}>
                        Как мы помогаем стоматологам
                    </span>
                    <span className={style.sliderSection__subtitle}>
                        Прочитайте о том, как dilab улучшает работу стоматолога
                        и клиники
                    </span>
                </div>
                <Slider {...settings}>
                    {sliderData.map((slideData) => (
                        <Slide {...slideData} key={id()} />
                    ))}
                </Slider>
                <div
                    className={classes(
                        style.slider__iconBlock,
                        style.slider__iconBlock_left
                    )}
                >
                    🙏
                </div>
                <div
                    className={classes(
                        style.slider__iconBlock,
                        style.slider__iconBlock__right
                    )}
                >
                    💯
                </div>
            </div>
        </div>
    );
};

export default SliderSection;
