import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';
import style from './PreviewModalWindow.module.scss';
import ReactDOM from 'react-dom';
import exitIcon from '@assets/images/closeModal.svg';
import arrow from '@assets/images/arrowDark.svg';
import { classes } from '@utils/classNames';
import {
    ICollectionPreview,
    ITemplate,
} from '@interfaces/collection.interface';
import Button from '@components/common/NEWDESIGN/Button/Button';
import plusIconSVG from '@images/addCircle.svg';
import {
    photoCollectionAPIs,
    PhotoCollectionApis,
} from '@APIs/photo-collection.apis';
import { presentationAPIs } from '@APIs/presentation.apis';
import { asyncLoadPresentations } from '@feature/presentations.slice';
import { setPresentationId } from '@feature/presentationId.slice';
import { ROUTES } from '@constants/routes';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useHistory } from 'react-router';
import { StorePresentation } from '@components/store/PresentationStorePage';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import ModalListItem from '@components/store/PreviewModalWindow/ModalListItem/ModalListItem';

const PreviewModalWindow = ({
    setIsModalShown,
    collections,
    collectionTemplates,
    announcements,
    selectedPresentationTemplate,
    isSliderLoading,
    isSidebarLoading = false,
}: {
    setIsModalShown: Dispatch<SetStateAction<boolean>>;
    collections?: ICollectionPreview[];
    collectionTemplates?: ITemplate[];
    selectedPresentationTemplate: StorePresentation | null;
    isSliderLoading: boolean;
    isSidebarLoading?: boolean;
    announcements: { page_number: number; announcement_url: string }[];
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isCollectionsVisible, setIsCollectionsVisible] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    // selectedCollectionId could be as collection id or as collection template id
    const [selectedCollectionId, setSelectedCollectionId] = useState<
        string | null
    >(null);

    const patientId = useMemo(
        () => location.pathname.split('/')[2],
        [location]
    );

    const isOnlyPreview = useMemo(
        () => !collections && !collectionTemplates,
        [collections, collectionTemplates]
    );

    const nextPage = () => {
        if (currentPage < announcements.length) {
            setCurrentPage(currentPage + 1);
            setIsImageLoading(true);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setIsImageLoading(true);
        }
    };

    const handleConfirm = useCallback(
        async (isCreatingNewCollection: boolean) => {
            if (!selectedCollectionId) {
                return;
            }

            try {
                let finalCollectionId = selectedCollectionId;
                let presentationId;

                if (isCreatingNewCollection) {
                    const { data: collections } =
                        await PhotoCollectionApis.createCollection(
                            patientId,
                            selectedCollectionId
                        );
                    finalCollectionId = collections[collections.length - 1]._id;
                }

                if (!isCreatingNewCollection) {
                    const { data: connectedPresentations } =
                        await photoCollectionAPIs.getConnectedPresentations(
                            selectedCollectionId
                        );
                    const existingPresentation = connectedPresentations.find(
                        (presentation) =>
                            presentation.key ===
                            selectedPresentationTemplate?.key
                    );

                    if (existingPresentation) {
                        presentationId = existingPresentation.id;
                    }
                }

                if (!presentationId && selectedPresentationTemplate) {
                    const { data: createdPresentation } =
                        await presentationAPIs.createPresentation(
                            selectedPresentationTemplate?.id,
                            patientId,
                            finalCollectionId
                        );
                    presentationId = createdPresentation.id;
                    dispatch(asyncLoadPresentations());
                }

                dispatch(setPresentationId(presentationId));

                const { data: collection } = await photoCollectionAPIs.getOne(
                    finalCollectionId,
                    presentationId
                );

                history.replace(
                    ROUTES.PATIENTS.$(patientId)
                        .PHOTOS.$(finalCollectionId)
                        .FILL.$(collection.children[0].id).END
                );
            } catch (error) {
                toast.error('Не удалось создать презентацию');
            }
        },
        [
            patientId,
            selectedCollectionId,
            selectedPresentationTemplate,
            dispatch,
            history,
        ]
    );

    return ReactDOM.createPortal(
        <div className={style.previewModal__container}>
            <div
                className={style.previewModal}
                style={{
                    width: isOnlyPreview ? '790px' : '1054px',
                }}
            >
                <div
                    className={style.previewModal__exitIconContainer}
                    onClick={() => setIsModalShown(false)}
                >
                    <img src={exitIcon} alt={'exitIcon'} />
                </div>
                <div className={style.previewModal__header}>
                    <span className={style.previewModal__title}>
                        {isOnlyPreview
                            ? 'Предпросмотр презентации'
                            : 'Создание презентации'}
                    </span>
                    <span className={style.previewModal__subtitle}>
                        {selectedPresentationTemplate?.name ?? ''}
                    </span>
                </div>
                <hr className={style.previewModal__dividerLine} />
                <div className={style.previewModal__contentBlock}>
                    {!isOnlyPreview && collections && collectionTemplates && (
                        <div className={style.previewModal__collectionsList}>
                            <div
                                className={style.createCollectionButton}
                                onClick={() => {
                                    setIsCollectionsVisible(
                                        (prevState) => !prevState
                                    );
                                    setSelectedCollectionId(null);
                                }}
                                style={{
                                    marginRight: !isCollectionsVisible
                                        ? '10px'
                                        : '0',
                                    marginLeft: isCollectionsVisible
                                        ? '10px'
                                        : '0',
                                }}
                            >
                                <img
                                    src={plusIconSVG}
                                    alt="plusIcon"
                                    className={classes(
                                        style.createCollectionButton__icon,
                                        {
                                            [style.createCollectionButton__icon_rotated]:
                                                !isCollectionsVisible,
                                        }
                                    )}
                                />
                                <span
                                    className={
                                        style.createCollectionButton__text
                                    }
                                >
                                    Новая фотоколлекция
                                </span>
                            </div>
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div
                                    className={classes(
                                        style.previewModal__collectionsGroup,
                                        {
                                            [style.previewModal__collectionsGroup_hidden]:
                                                isCollectionsVisible,
                                        }
                                    )}
                                >
                                    {collectionTemplates.map((template) => (
                                        <ModalListItem
                                            key={template._id}
                                            text={template.title}
                                            onClick={() =>
                                                setSelectedCollectionId(
                                                    template._id
                                                )
                                            }
                                            isSelected={
                                                template._id ===
                                                selectedCollectionId
                                            }
                                            isMarked={
                                                template.key ===
                                                selectedPresentationTemplate?.collection_key
                                            }
                                        />
                                    ))}
                                </div>
                                <div
                                    className={classes(
                                        style.previewModal__collectionsGroup,
                                        {
                                            [style.previewModal__collectionsGroup_hidden]:
                                                !isCollectionsVisible,
                                        }
                                    )}
                                >
                                    {isSidebarLoading ? (
                                        <CustomLoader size={25} />
                                    ) : (
                                        collections.map((collection) => (
                                            <ModalListItem
                                                key={collection.id}
                                                text={collection.title}
                                                onClick={() =>
                                                    setSelectedCollectionId(
                                                        collection.id
                                                    )
                                                }
                                                isSelected={
                                                    selectedCollectionId ===
                                                    collection.id
                                                }
                                                date={collection.created_at}
                                            />
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={style.previewModal__previewBlock}>
                        <div className={style.previewModal__imgContainer}>
                            <img
                                src={
                                    announcements[currentPage - 1]
                                        ?.announcement_url ?? ''
                                }
                                alt={`preview slide ${currentPage}`}
                                width={'750px'}
                                height={'425px'}
                                onLoad={() => setIsImageLoading(false)}
                                style={{
                                    opacity:
                                        isImageLoading || isSliderLoading
                                            ? 0
                                            : 1,
                                }}
                            />
                            {(isImageLoading || isSliderLoading) && (
                                <div
                                    className={
                                        style.previewModal__loaderContainer
                                    }
                                >
                                    <CustomLoader size={25} />
                                </div>
                            )}
                        </div>
                        <div className={style.previewModal__footer}>
                            <div
                                className={
                                    style.previewModal__navigationContainer
                                }
                            >
                                <div
                                    className={classes(
                                        style.previewModal__previousButton,
                                        {
                                            [style.previewModal__disabledButton]:
                                                currentPage === 1,
                                        }
                                    )}
                                    onClick={previousPage}
                                >
                                    <img src={arrow} alt={'arrow'} />
                                </div>
                                <div
                                    className={classes(
                                        style.previewModal__nextButton,
                                        {
                                            [style.previewModal__disabledButton]:
                                                currentPage ===
                                                announcements.length,
                                        }
                                    )}
                                    onClick={nextPage}
                                >
                                    <img src={arrow} alt={'arrow'} />
                                </div>
                            </div>
                            <div
                                className={
                                    style.previewModal__paginationContainer
                                }
                            >
                                <span>
                                    Слайд{' '}
                                    {announcements.length ? currentPage : 0} из{' '}
                                    {announcements.length}
                                </span>
                            </div>
                            <div
                                className={style.previewModal__buttonContainer}
                            >
                                <Button
                                    text="Готово"
                                    color="BLUE"
                                    onClick={
                                        isOnlyPreview
                                            ? () => setIsModalShown(false)
                                            : () => {
                                                  setIsButtonLoading(true);
                                                  handleConfirm(
                                                      !isCollectionsVisible
                                                  ).then(() => {
                                                      setIsModalShown(false);
                                                      setIsButtonLoading(false);
                                                  });
                                              }
                                    }
                                    padding="6px 12px"
                                    isActive={
                                        isOnlyPreview
                                            ? true
                                            : !!selectedCollectionId
                                    }
                                    isLoading={isButtonLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default PreviewModalWindow;
