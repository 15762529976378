import React from 'react';
import ResetPasswordHeader from './header/ResetPasswordHeader';
import ResetPasswordBody from '@components/resetpassword/body/ResetPasswordBody';
import style from './ResetPasswordPage.module.scss';
import ResetPasswordFooter from '@components/resetpassword/footer/ResetPasswordFooter';

const ResetPasswordPage = (): JSX.Element => (
    <div className={style.resetPasswordPage}>
        <ResetPasswordHeader />
        <ResetPasswordBody />
        <ResetPasswordFooter />
    </div>
);

export default ResetPasswordPage;
