import React from 'react';
import style from './RegistrationHeader.module.scss';
import { Link } from 'react-router-dom';
import { ROUTES } from '@constants/routes';
import logo from '@images/logo.svg';

const RegistrationHeader = (): JSX.Element => {
    return (
        <div className={style.registrationHeader}>
            <img
                alt="menu logo"
                className={style.registrationHeader__logo}
                src={logo}
            />
            <div className={style.registrationHeader__loginBlock}>
                <p className={style.registrationHeader__loginText}>
                    Вы уже с нами?
                </p>
                <Link to={ROUTES.LOGIN.END} style={{ textDecoration: 'none' }}>
                    <button className={style.registrationHeader__loginButton}>
                        <span>Войти</span>
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default RegistrationHeader;
