import React from 'react';
import RegistrationForm from './form/RegistrationForm';
import RegistrationHeader from './header/RegistrationHeader';
import styles from './Registration.module.scss';

const Registration = (): JSX.Element => (
    <div className={styles.registrationPage}>
        <RegistrationHeader />
        <RegistrationForm />
    </div>
);

export default Registration;
