import styles from './TemplatePreview.module.scss';
import React from 'react';
import { StorePresentation } from '@components/store/PresentationStorePage';
import Button from '@components/common/NEWDESIGN/Button/Button';

type TemplatePreviewProps = {
    item: StorePresentation;
    buttonHandlers: {
        title: string;
        onClickFunction: () => void;
        color?: 'BLUE' | 'GRAY' | 'WHITE';
        padding?: string;
    }[];
};

export const TemplatePreview = ({
    item,
    buttonHandlers,
}: TemplatePreviewProps): JSX.Element => {
    return (
        <div className={styles.templatePreview}>
            <div className={styles.templatePreview__imgContainer}>
                <div className={styles.templatePreview__ButtonsContainer}>
                    {buttonHandlers.map((buttonHandler) => (
                        <Button
                            key={buttonHandler.title}
                            color={buttonHandler.color}
                            padding={buttonHandler.padding ?? '7px 43px'}
                            text={buttonHandler.title}
                            onClick={buttonHandler.onClickFunction}
                        />
                    ))}
                </div>
                <img
                    className={styles.templatePreview__img}
                    src={item.preview}
                    alt="превью презентации"
                />
            </div>
            <p className={styles.templatePreview__title}>{item.name}</p>
            <div className={styles.templatePreview__description}>
                <p>{item.description}</p>
            </div>
        </div>
    );
};
