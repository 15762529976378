import React, { useCallback, useEffect, useState } from 'react';
import { ROUTES } from '@constants/routes';
import { Link } from 'react-router-dom';
import style from './RegistrationForm.module.scss';
import { useForm } from 'react-hook-form';
import { useAuth } from '@contexts/AuthContext';
import { useHistory } from 'react-router';
import { AuthorizationApis } from '@APIs/auth.apis';
import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import Button, { ButtonState } from '@components/common/button/Button';

export type FormDataType = {
    name: string;
    surname: string;
    email: string;
    password: string;
    confirmPassword: string;
};

const RegistrationForm = (): JSX.Element => {
    const { isAuthenticated, setJWT } = useAuth();
    const history = useHistory();

    const [requestError, setRequestError] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
        getValues,
        watch,
    } = useForm<FormDataType>({ mode: 'onChange' });

    const onSubmit = useCallback(
        async (formData: FormDataType) => {
            setIsLoading(true);
            try {
                const res = await AuthorizationApis.createAccount(formData);
                if (res) {
                    if (
                        res.status === 200 ||
                        res.status === 201 ||
                        res.status === 204 ||
                        res.status === 304
                    ) {
                        setRequestError('');
                        setJWT(res.data);
                        history.push(ROUTES.VERIFY.END);
                    } else {
                        setRequestError(res.data);
                    }
                }
            } finally {
                setIsLoading(false);
            }
        },
        [setIsLoading, setRequestError, setJWT, history]
    );

    useEffect(() => {
        if (isAuthenticated) history.push(ROUTES.PATIENTS.END);
    }, [isAuthenticated, history]);

    const isDisabled = useCallback(
        () =>
            Object.values(dirtyFields).length !==
            Object.keys(getValues()).length,
        [dirtyFields]
    );

    return (
        <div className={style.registrationForm__container}>
            <div className={style.registrationForm}>
                <div className={style.registrationForm__titleBlock}>
                    <span className={style.registrationForm__title}>
                        Создание нового пользователя
                    </span>
                    <span className={style.registrationForm__subtitle}>
                        Уже зарегистрированы?
                        <Link
                            to={ROUTES.LOGIN.END}
                            style={{ textDecoration: 'none' }}
                        >
                            <span
                                className={
                                    style.registrationForm__subtitle_accent
                                }
                            >
                                {' '}
                                Войти
                            </span>
                        </Link>
                    </span>
                </div>

                <div className={style.registrationForm__formBlock}>
                    <form
                        className={style.registrationForm__form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <InputField
                            label="Имя"
                            placeholder="Введите ваше имя"
                            fieldName="name"
                            type="PLAINTEXT"
                            register={register('name', validators['NAME'])}
                            errorText={errors.name?.message}
                            className={style.registrationForm__input}
                        />
                        <InputField
                            label="Фамилия"
                            placeholder="Введите вашу фамилию"
                            fieldName="surname"
                            type="PLAINTEXT"
                            register={register('surname', validators['NAME'])}
                            errorText={errors.surname?.message}
                            className={style.registrationForm__input}
                        />
                        <InputField
                            label="Почта"
                            placeholder="Введите свой email-адрес"
                            fieldName="email"
                            type="EMAIL"
                            register={register('email', validators['EMAIL'])}
                            errorText={errors.email?.message}
                            className={style.registrationForm__input}
                        />
                        <InputField
                            label="Пароль"
                            placeholder="Введите свой пароль"
                            fieldName="password"
                            type="PASSWORD"
                            register={register(
                                'password',
                                validators['NEWPASSWORD']
                            )}
                            errorText={errors.password?.message}
                            className={
                                style.registrationForm__input_withBigMargin
                            }
                        />
                        <InputField
                            label="Повторите пароль"
                            placeholder="Введите пароль еще раз"
                            fieldName="confirmPassword"
                            type="PASSWORD"
                            register={register('confirmPassword', {
                                validate: (val: string) => {
                                    if (watch('password') != val) {
                                        return 'Пароли не совпадают';
                                    }
                                },
                            })}
                            errorText={errors.confirmPassword?.message}
                            className={
                                style.registrationForm__input_withBigMargin
                            }
                        />
                        <div
                            className={style.registrationForm__buttonContainer}
                        >
                            <Button
                                title="Создать"
                                error={requestError}
                                isLoading={isLoading}
                                state={
                                    isDisabled()
                                        ? ButtonState.INACTIVE
                                        : ButtonState.ACTIVE
                                }
                                type="submit"
                            />
                        </div>
                    </form>
                    <p className={style.registrationForm__privacyPolicyText}>
                        Нажимая, вы соглашаетесь с
                        <a
                            target="_blank"
                            href="https://dilab.ru/policy/"
                            rel="noreferrer noopener"
                            className={
                                style.registrationForm__privacyPolicyText_accent
                            }
                        >
                            {' '}
                            политикой <br /> конфиденциальности
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
