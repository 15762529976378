import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';

import { IUserProfile } from '@interfaces/authentication';
import { AxiosInstance } from '@APIs/axiosInstance';
import { AuthorizationApis as AuthAPIs } from '@APIs/auth.apis';

type AuthProviderProps = {
    children: ReactNode;
};

interface AuthContextValue {
    token?: string;
    isAuthenticated: boolean;
    setJWT: React.Dispatch<React.SetStateAction<string | undefined>>;
    setProfile: React.Dispatch<React.SetStateAction<IUserProfile>>;
    profile: IUserProfile;
    isLoadingProfile: boolean;
    profilePicture: string;
    setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextValue | undefined>(
    undefined
);

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [jwt, setJWT] = useState<string | undefined>(
        window.localStorage.getItem('token') || undefined
    );

    const [isAuthenticated, setIsAuthenticated] = useState(!!jwt);
    const [profile, setProfile] = useState<IUserProfile>({} as IUserProfile);
    const [isLoadingProfile, setIsLoadingProfile] = useState(true);
    const [profilePicture, setProfilePicture] = useState('');

    useEffect(() => {
        if (jwt) {
            const jwtInterceptor = AxiosInstance.interceptors.request.use(
                (config) => {
                    if (jwt) {
                        config.headers['Authorization'] = `Bearer ${jwt}`;
                    }
                    return config;
                }
            );

            return () =>
                AxiosInstance.interceptors.request.eject(jwtInterceptor);
        }
    }, [jwt]);

    useEffect(() => {
        if (jwt) {
            AuthAPIs.getProfile()
                .then((res: { data: IUserProfile; status: number }) => {
                    if (res.status === 200 || res.status === 204) {
                        setProfile(res.data);
                        setIsAuthenticated(true);
                        window.localStorage.setItem('token', jwt);
                        setIsLoadingProfile(false);
                    } else {
                        setIsAuthenticated(false);
                        window.localStorage.removeItem('token');
                        setJWT(undefined);
                        setIsLoadingProfile(false);
                    }
                })
                .catch((error) => {
                    console.error(error);

                    setJWT(undefined);
                    setIsLoadingProfile(false);
                    if (error.response?.status === 401) {
                        setJWT(undefined);
                        setIsLoadingProfile(false);
                    }
                });
        } else {
            setIsLoadingProfile(false);
            setIsAuthenticated(false);
        }
    }, [jwt]);

    useEffect(() => {
        setProfilePicture(profile.avatar ?? '');
    }, [profile.avatar]);

    return (
        <AuthContext.Provider
            value={{
                token: jwt,
                isAuthenticated,
                setJWT,
                profile,
                setProfile,
                isLoadingProfile,
                profilePicture,
                setProfilePicture,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextValue => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth can only be used inside AuthProvider');
    }

    return context;
};
