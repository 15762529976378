import slide1 from '@images/slide1.png';

export const sliderData = [
    {
        title: 'Как мы помогаем стоматологом?',
        subtitle:
            'Прочитайте о том, как dilab улучшает работу стоматолога и клиники ',
        comment:
            'Простой и понятный инструмент, который помогает улучшить качество услуг и дать пациенту то, что он ожидает.',
        imgLink: slide1,
        doctorFullName: 'Епифанов Иван',
        doctorPosition: 'Главный врач клиники “Епсилон”',
    },
    {
        title: 'Как мы помогаем стоматологом?',
        subtitle:
            'Прочитайте о том, как dilab улучшает работу стоматолога и клиники ',
        comment:
            'Простой и понятный инструмент, который помогает улучшить качество услуг и дать пациенту то, что он ожидает.',
        imgLink: slide1,
        doctorFullName: 'Епифанов Иван',
        doctorPosition: 'Главный врач клиники “Епсилон”',
    },
    {
        title: 'Как мы помогаем стоматологом?',
        subtitle:
            'Прочитайте о том, как dilab улучшает работу стоматолога и клиники ',
        comment:
            'Простой и понятный инструмент, который помогает улучшить качество услуг и дать пациенту то, что он ожидает.',
        imgLink: slide1,
        doctorFullName: 'Епифанов Иван',
        doctorPosition: 'Главный врач клиники “Епсилон”',
    },
    {
        title: 'Как мы помогаем стоматологом?',
        subtitle:
            'Прочитайте о том, как dilab улучшает работу стоматолога и клиники ',
        comment:
            'Простой и понятный инструмент, который помогает улучшить качество услуг и дать пациенту то, что он ожидает.',
        imgLink: slide1,
        doctorFullName: 'Епифанов Иван',
        doctorPosition: 'Главный врач клиники “Епсилон”',
    },
];
