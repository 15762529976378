import React from 'react';
import style from './Slide.module.scss';
import doctorPhoto from '@images/slide1.png';

const Slide = ({
    title,
    subtitle,
    comment,
    imgLink,
    doctorFullName,
    doctorPosition,
}: {
    title: string;
    subtitle: string;
    comment: string;
    imgLink: string;
    doctorFullName: string;
    doctorPosition: string;
}) => {
    return (
        <div className={style.slide}>
            <div className={style.slide__feedbackBlock}>
                <span className={style.slide__commentText}>{comment}</span>
                <div className={style.slide__doctorBlock}>
                    <div className={style.slide__doctorImage}>
                        <img src={imgLink} alt={'doctor photo'} />
                    </div>
                    <div>
                        <span className={style.slide__doctorFullName}>
                            {doctorFullName}
                        </span>
                        <span className={style.slide__doctorPosition}>
                            {doctorPosition}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slide;
