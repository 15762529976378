import { loadWorker } from '@utils/download.worker';

export function useLoader(): {
    download: (url: string) => void;
} {
    const loader = loadWorker();

    loader.onmessage = ({ data }) => {
        const a = document.createElement('a');
        a.download = 'image.jpg';
        a.href = data;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const download = (url: string) => loader.postMessage(url);

    return {
        download,
    };
}
