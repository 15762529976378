import LoadingIndicator from '@components/patient/patientinfo/LoadingIndicator';
import React from 'react';
import styles from './SupportPage.module.scss';

const SupportPage = (): JSX.Element => {
    return (
        <div className={styles.Container}>
            <div className={styles.TitleContainer}>
                <p className={styles.Title}>Поддержка</p>
            </div>
            <LoadingIndicator isLoading={false} />
            <div className={styles.Page}>
                <div>
                    <h3>Что такое dilab?</h3>
                    <p>
                        Это сервис для врачей, который помогает автоматизировать
                        рутинные задачи, делать лечение лучше и удобно делиться
                        результатами с пациентом.
                    </p>
                </div>
                <div>
                    <h3>Для кого этот сервис?</h3>
                    <p>
                        Этот сервис расчитан как на частных врачей, так и для
                        компаний с большим штатом сотрудников.
                    </p>
                </div>
                <div>
                    <h3>Какие функции предлагает dilab?</h3>
                    <p>
                        dilab стремительно развивается и на данный момент сервис
                        позволяет автоматизировать создание презентаций для
                        первичной диагности, оценки лечения и промежуточных
                        результатов. Также доступен расчет ТРГ пациента по
                        основному методу.
                    </p>
                </div>
                <div>
                    <h3>Как проводится покупка подписки?</h3>
                    <p>
                        На данный момент оплата производится каждый месяц без
                        автоматического продления.
                    </p>
                </div>
                <div>
                    <h3>Почему недоступны пациенты, презентации или ТРГ?</h3>
                    <p>
                        Бесплатный тариф имеет ограничения на количество
                        пользователей и их доступность. Вы можете работать с
                        ограниченным количеством пациентов, презентаций, и ТРГ и
                        только в рамках одного календарного месяца. Все данные
                        будут доступны после оплаты подписки Pro.
                    </p>
                </div>
                <div className={styles.ChatContainer}>
                    Напишите нам в чат, если не нашли ответ выше.
                    <br />
                    <div
                        onClick={() =>
                            window.open(
                                'https://t.me/dilab_suport_bot',
                                '_blank'
                            )
                        }
                        className={styles.Button}
                    >
                        <div className={styles.Telegram} />
                        <p>Телеграмм</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
