import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import style from './SavePasswordForm.module.scss';
import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import Button, { ButtonState } from '@components/common/button/Button';

type PasswordFormProps = {
    onSubmit: (formData: PasswordFormData) => Promise<void>;
    error?: string;
    isLoading: boolean;
};

export type PasswordFormData = {
    password: string;
    confirmPassword: string;
};
export const SavePasswordForm = ({
    onSubmit,
    error,
    isLoading,
}: PasswordFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<PasswordFormData>({ mode: 'onChange' });

    const password = watch('password', '');
    const repassword = watch('confirmPassword', '');

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        setIsDisabled(
            password.length < 8 ||
                repassword.length < 8 ||
                password !== repassword
        );
    }, [repassword, password]);

    return (
        <div className={style.form__container}>
            <div className={style.form}>
                <span className={style.form__headerText}>
                    Сброс Пароля
                    <br />
                    Введите новый пароль
                </span>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        label="Пароль"
                        placeholder="Введите новый пароль"
                        fieldName="password"
                        type="PASSWORD"
                        isRequired={false}
                        register={register(
                            'password',
                            validators['NEWPASSWORD']
                        )}
                        errorText={errors.password?.message}
                        className={style.form__input}
                    />
                    <InputField
                        label="Повторите пароль"
                        placeholder="Введите пароль еще раз"
                        fieldName="confirmPassword"
                        type="PASSWORD"
                        isRequired={false}
                        register={register('confirmPassword', {
                            validate: (val: string) => {
                                if (watch('password') != val) {
                                    return 'Пароли не совпадают';
                                }
                            },
                        })}
                        errorText={errors.confirmPassword?.message}
                        className={style.form__input}
                    />
                    <div>
                        <Button
                            title="Изменить пароль"
                            isLoading={isLoading}
                            state={
                                isDisabled
                                    ? ButtonState.INACTIVE
                                    : ButtonState.ACTIVE
                            }
                            type="submit"
                        />
                    </div>
                    {error && (
                        <span className={style.form__errorMessage}>
                            {error}
                        </span>
                    )}
                </form>
            </div>
        </div>
    );
};
