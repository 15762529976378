import React, { useEffect, useState } from 'react';
import styles from '@components/store/PresentationStorePage.module.scss';
import { getPresentationTemplates } from '@utils/presentation';
import { CustomLoader } from '../common/loadingspinner/LoadingSpinner';
import {
    selectCollections,
    selectCollectionsLoadStatus,
} from '@feature/collections.slice';
import { useAppSelector } from '@hooks/useAppSelector';
import { TemplatePreview } from '@components/store/TemplatePreview/TemplatePreview';
import { PhotoCollectionApis } from '@APIs/photo-collection.apis';
import { ITemplate } from '@interfaces/collection.interface';
import { toast } from 'react-toastify';
import PreviewModalWindow from '@components/store/PreviewModalWindow/PreviewModalWindow';
import { IAnnouncements } from '@interfaces/presentation.interface';
import { presentationAPIs } from '@APIs/presentation.apis';

// The element represent available Presentations
export interface StorePresentation {
    name: string;
    description: string;
    preview: string;
    id: string;
    date: number;
    key: string;
    collection_key: string;
    keywords: string[];
    message: string;
    pages: number;
}

const PresentationStorePage = (): JSX.Element => {
    const [presentationTemplates, setPresentationTemplates] = useState<
        StorePresentation[]
    >([]);
    const [collectionTemplates, setCollectionTemplates] = useState<ITemplate[]>(
        []
    );
    const [selectedPresentationTemplate, setSelectedPresentationTemplate] =
        useState<StorePresentation | null>(null);
    const [selectedAnnouncements, setSelectedAnnouncements] =
        useState<IAnnouncements>();
    const [isModalShown, setIsModalShown] = useState(false);
    const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false);
    const collections = useAppSelector(selectCollections);
    const isCollectionsLoading = useAppSelector(selectCollectionsLoadStatus);

    useEffect(() => {
        Promise.all([
            getPresentationTemplates(),
            PhotoCollectionApis.getTemplates(),
        ])
            .then(
                ([
                    presentationTemplatesResponse,
                    collectionTemplatesResponse,
                ]) => {
                    setPresentationTemplates(
                        presentationTemplatesResponse.data
                    );
                    setCollectionTemplates(collectionTemplatesResponse.data);
                }
            )
            .catch(() => {
                toast.error('Не удалось загрузить шаблоны, попробуйте позже');
            });
    }, []);

    useEffect(() => {
        if (selectedPresentationTemplate) {
            setIsAnnouncementsLoading(true);
            presentationAPIs
                .getAnnouncements(selectedPresentationTemplate.id)
                .then(({ data }) => {
                    setSelectedAnnouncements(data);
                })
                .finally(() => {
                    setIsAnnouncementsLoading(false);
                });
        }
    }, [selectedPresentationTemplate]);

    return (
        <>
            <div className={styles.store_root}>
                {presentationTemplates ? (
                    <div className={styles.store_grid_root}>
                        {presentationTemplates.map((template) => (
                            <TemplatePreview
                                key={template.id}
                                item={template}
                                buttonHandlers={[
                                    {
                                        title: 'Создать',
                                        onClickFunction: () => {
                                            setSelectedPresentationTemplate(
                                                template
                                            );
                                            setIsModalShown(true);
                                        },
                                        color: 'BLUE',
                                    },
                                ]}
                            />
                        ))}
                    </div>
                ) : (
                    <div className={styles.store_loading}>
                        <CustomLoader size={44} />
                    </div>
                )}
            </div>
            {isModalShown && (
                <PreviewModalWindow
                    setIsModalShown={setIsModalShown}
                    collections={collections}
                    collectionTemplates={collectionTemplates}
                    announcements={selectedAnnouncements?.announcements ?? []}
                    selectedPresentationTemplate={selectedPresentationTemplate}
                    isSliderLoading={isAnnouncementsLoading}
                    isSidebarLoading={isCollectionsLoading !== 'successfully'}
                />
            )}
        </>
    );
};

export default PresentationStorePage;
