import React, { FC } from 'react';

import { Modal } from '@components/UI/Modal';
import { IReleaseNote } from '@interfaces/IReleaseNotes';

import styles from './WhatNewsModal.module.scss';

interface IWhatNewsModal {
    isOpen: boolean;
    onClose: VoidFunction;
    releaseNotesData?: IReleaseNote;
}

export const WhatNewsModal: FC<IWhatNewsModal> = ({
    isOpen,
    onClose,
    releaseNotesData,
}) => {
    const currentDate = releaseNotesData?.date
        ? new Date(releaseNotesData.date)
        : new Date();

    const formattedDate = currentDate.toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <Modal
            title="Что нового?"
            isOpen={isOpen}
            onClose={onClose}
            disableDefaultPadding
        >
            <div className={styles.content}>
                <p className={styles.date}>{formattedDate}</p>
                <div className={styles.scrollArea}>
                    {releaseNotesData?.image_url && (
                        <div className={styles.imgContainer}>
                            <img
                                src={releaseNotesData.image_url}
                                alt="dilabImg"
                                className={styles.img}
                            />
                        </div>
                    )}
                    <div
                        className={styles.backData}
                        dangerouslySetInnerHTML={{
                            __html: releaseNotesData?.release_notes || '',
                        }}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerText}>
                    Чтобы узнать подробнее{' '}
                    <a
                        href="http://t.me/dilab_app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.footerLink}
                    >
                        нажмите сюда
                    </a>
                </div>
            </div>
        </Modal>
    );
};
