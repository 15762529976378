import { useEffect } from 'react';

interface ButtonEvent {
    keys: string[];
    callback: (e: KeyboardEvent) => void;
}

const useButtonListeners = (buttonEvents: ButtonEvent[]) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            buttonEvents.forEach((buttonEvent) => {
                if (buttonEvent.keys.includes(e.key)) {
                    buttonEvent.callback(e);
                }
            });
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [buttonEvents]);
};

export default useButtonListeners;
