import React from 'react';
import style from './GetVerificationCodeForm.module.scss';
import { useForm } from 'react-hook-form';

import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import Button, { ButtonState } from '@components/common/button/Button';

type SentVerificationCodeFormProps = {
    onSubmit: (formData: EmailFormData) => Promise<void>;
    error?: string;
    isLoading: boolean;
};

export type EmailFormData = {
    email: string;
};

export const GetVerificationCodeForm = ({
    onSubmit,
    error,
    isLoading,
}: SentVerificationCodeFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<EmailFormData>({ mode: 'onChange' });

    return (
        <div className={style.sendCodeForm__container}>
            <div className={style.sendCodeForm}>
                <div className={style.sendCodeForm__header}>
                    <span className={style.sendCodeForm__headerText}>
                        <span
                            className={
                                style.sendCodeForm__headerText_capitalized
                            }
                        >
                            Сброс Пароля
                        </span>
                        <br />
                        Введите вашу почту
                    </span>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        label="Email"
                        placeholder="Введите свой email адрес"
                        fieldName="email"
                        type="EMAIL"
                        isDisabled={false}
                        isRequired={true}
                        errorText={errors.email?.message}
                        register={register('email', validators.EMAIL)}
                        className={style.sendCodeForm__input}
                    />
                    <div>
                        <Button
                            title="Отправить код"
                            state={
                                isValid
                                    ? ButtonState.ACTIVE
                                    : ButtonState.INACTIVE
                            }
                            isLoading={isLoading}
                            error={error}
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
