import React from 'react';
import MailVerificationBody from '@components/verification/body/MailVerificationBody';
import style from './MailVerificationPage.module.scss';
import SliderSection from '@components/verification/sliderSection/SliderSection';

const MailVerificationPage = (): JSX.Element => {
    return (
        <div className={style.mailVerificationPage}>
            <div className={style.mailVerificationPage__bodyContainer}>
                <MailVerificationBody />
            </div>
            <div className={style.mailVerificationPage__sliderSectionContainer}>
                <SliderSection />
            </div>
        </div>
    );
};

export default MailVerificationPage;
