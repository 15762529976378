import React, { useCallback, useEffect, useState, useRef } from 'react';
import style from './MailVerificationBody.module.scss';
import { useHistory } from 'react-router';
import { useAuth } from '@contexts/AuthContext';
import { ROUTES } from '@constants/routes';
import { AuthorizationApis } from '@APIs/auth.apis';
import { AxiosInstance } from '@APIs/axiosInstance';
import logo from '@images/logo.svg';
import { classes } from '@utils/classNames';

const MailVerificationBody = (): JSX.Element => {
    const { profile, setProfile } = useAuth();
    const history = useHistory();
    const [isJustSent, setIsJustSent] = useState(false);
    const { setJWT } = useAuth();
    const [isConfirmed, setIsConfirmed] = useState(true);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const switchUser = () => {
        window.localStorage.removeItem('token');
        setJWT(undefined);
        history.push(ROUTES.LOGIN.END);
    };

    useEffect(() => {
        if (profile.isEmailValid) history.push(ROUTES.PATIENTS.END);
    }, [profile.isEmailValid, history]);

    const [timer, setTimer] = useState(0);

    const clearTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    };

    const countdown = useCallback(() => {
        setTimer((prev) => prev - 1);
    }, []);

    const stopwatch = useCallback(
        (seconds: number) => {
            clearTimer();
            setTimer(seconds);
            timerRef.current = setInterval(countdown, 1000);
        },
        [countdown]
    );

    useEffect(() => {
        stopwatch(120);
        return clearTimer;
    }, []);

    useEffect(() => {
        if (isJustSent) {
            stopwatch(10);
            setIsJustSent(false);
        }
    }, [isJustSent, stopwatch]);

    const resendVerifyLink = useCallback(() => {
        if (profile && timer <= 0) {
            AxiosInstance.put('/auth/resend-verfiy-link', {
                email: profile.email,
            })
                .then(() => {
                    setIsJustSent(true);
                })
                .catch((err: { message: string | undefined }) => {
                    throw new Error(err.message);
                });
        }
    }, [profile, timer]);

    const secondsToTime = (seconds: number) => {
        return `${Math.floor(seconds / 60)}:${Math.floor(seconds % 60)
            .toString()
            .padStart(2, '0')}`;
    };

    const finished = useCallback(() => {
        AuthorizationApis.getProfile()
            .then(({ data, status }) => {
                if (status === 200 || status === 204) {
                    setProfile(data);
                }
                if (!data.isEmailValid) {
                    setIsConfirmed(false);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [setProfile]);

    return (
        <div className={style.body__container}>
            <div className={style.body}>
                <div className={style.body__header}>
                    <img className={style.body__logo} src={logo} alt="logo" />
                </div>
                <div className={style.body__contentBlock}>
                    <span className={style.body__title}>
                        Подтвердите свой email 👋
                    </span>
                    <span className={style.body__subtitle}>
                        На адрес{' '}
                        <span className={style.body__subtitle_accent}>
                            {profile.email}
                        </span>{' '}
                        отправлено письмо перейдите по ссылке для подтверждения.
                    </span>
                    <div className={style.body__timerBlock}>
                        <span
                            className={classes(style.body__resendButton, {
                                [style.body__resendButton_disabled]: timer > 0,
                            })}
                            onClick={resendVerifyLink}
                        >
                            Отправить заново
                        </span>
                        <span className={style.body__timer}>
                            {timer > 0 && secondsToTime(timer)}
                        </span>
                    </div>
                    <div className={style.body__acceptButtonContainer}>
                        <button
                            type="button"
                            className={style.body__acceptButton}
                            onClick={finished}
                        >
                            Войти
                        </button>
                        {!isConfirmed && (
                            <span className={style.body__errorMessage}>
                                Вы не подтвердили почту
                            </span>
                        )}
                    </div>

                    <div className={style.body__switchUserButton}>
                        <span onClick={switchUser}>Сменить пользователя</span>
                    </div>
                </div>
                <div>
                    <p className={style.body__privacyPolicyText}>
                        Нажимая, вы соглашаетесь с
                        <a
                            className={style.body__privacyPolicyText_link}
                            target="_blank"
                            href="https://dilab.ru/policy/"
                            rel="noreferrer noopener"
                        >
                            {' '}
                            политикой <br /> конфиденциальности
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MailVerificationBody;
