import React from 'react';
import style from './SuccessMessage.module.scss';
import Button, { ButtonState } from '@components/common/button/Button';

const SuccessMessage = ({ onSuccessReset }: { onSuccessReset: () => void }) => {
    return (
        <div className={style.successMessage}>
            <div className={style.successMessage__contentBlock}>
                <span className={style.successMessage__title}>
                    Пароль успешно изменен
                </span>

                <Button
                    title="Войти"
                    state={ButtonState.ACTIVE}
                    onClick={onSuccessReset}
                />
            </div>
        </div>
    );
};

export default SuccessMessage;
