import React from 'react';
import { useForm } from 'react-hook-form';

import InputField from '@components/inputfield/InputField';
import { validators } from '@components/inputfield/helpers/validation';
import style from './CheckVerificationCodeForm.module.scss';
import Button, { ButtonState } from '@components/common/button/Button';
import { AuthorizationApis } from '@APIs/auth.apis';

type VerificationCodeFormProps = {
    onSubmit: (formData: CodeFormData) => Promise<void>;
    error?: string;
    isLoading: boolean;
    email: string;
};

export type CodeFormData = {
    code: string;
    email: string;
};

export const CheckVerificationCodeForm = ({
    onSubmit,
    error,
    isLoading,
    email,
}: VerificationCodeFormProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<CodeFormData>({ mode: 'onBlur' });

    const code = watch('code', '');
    return (
        <div className={style.verifyCodeForm__container}>
            <div className={style.verifyCodeForm}>
                <div className={style.verifyCodeForm__header}>
                    <p className={style.verifyCodeForm__headerText}>
                        <span
                            className={
                                style.verifyCodeForm__headerText_capitalized
                            }
                        >
                            сброс пароля <br />
                        </span>
                        Введите код, который был отправлен на почту{' '}
                        <span
                            className={style.verifyCodeForm__headerText_accent}
                        >
                            {email}
                        </span>
                    </p>
                </div>
                <div className="">
                    <form
                        className="reset--password__form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <InputField
                            label="Код подтверждения"
                            placeholder="Введите код"
                            fieldName="code"
                            type="PLAINTEXT"
                            isRequired={false}
                            register={register('code', validators['RESETCODE'])}
                            errorText={errors.code?.message}
                            className={style.verifyCodeForm__input}
                            additionalText={'Отправить код еще раз?'}
                            onAdditionalTextClick={() =>
                                AuthorizationApis.sendResetVerificationCode(
                                    email
                                )
                            }
                            timerTime={120}
                        />

                        <Button
                            title="Подтвердить код"
                            state={
                                code.length !== 6
                                    ? ButtonState.INACTIVE
                                    : ButtonState.ACTIVE
                            }
                            isLoading={isLoading}
                            error={error}
                            type="submit"
                            extraStyle={{ width: '100%' }}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
