import React, { useEffect } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

// хэндлер изменения типа курсора при наведении на фигуру
const useCursor = (shapeRef: React.RefObject<any>, isSelected: boolean) => {
    useEffect(() => {
        const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
            const container = e.target.getStage()?.container();
            if (container) {
                container.style.cursor = isSelected ? 'move' : 'pointer';
            }
        };

        const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
            const container = e.target.getStage()?.container();
            if (container) {
                container.style.cursor = 'default';
            }
        };

        const shape = shapeRef.current;
        if (shape) {
            shape.on('mouseenter', handleMouseEnter);
            shape.on('mouseleave', handleMouseLeave);

            return () => {
                shape.off('mouseenter', handleMouseEnter);
                shape.off('mouseleave', handleMouseLeave);
            };
        }
    }, [isSelected, shapeRef]);
};

export default useCursor;
