import React from 'react';
import styles from './StorageModal.module.scss';
import { IArchiveCollection } from '@interfaces/collection.interface';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { pushFolderToPath } from '@store/feature/folder.slice';
import { FolderIcon } from '@components/UI/FolderIcon';
import { classes } from '@utils/classNames';

export const FoldersTable = ({
    data,
    onFolderChange,
    setSelectedFolderId,
    selectedFolderId,
}: {
    data: IArchiveCollection[];
    onFolderChange: (id: string) => void;
    setSelectedFolderId: (id: string) => void;
    selectedFolderId: string | null;
}): JSX.Element => {
    const dispatch = useAppDispatch();

    const isLeaf = (folder: IArchiveCollection) =>
        !folder.children.filter((el) => el.type === 'FOLDER').length;
    const isEmpty = (folder: IArchiveCollection) =>
        !folder.children.filter((el) => el.type === 'FILE').length;

    return (
        <div className={styles.Table}>
            <div className={styles.TableHeader}>
                <div className={styles.HeaderCell}>Название</div>
                <div className={styles.HeaderCell}>Дата</div>
                <div className={styles.HeaderCell}>Файлы</div>
            </div>
            <div className={styles.TableBody}>
                {data.map((el) => {
                    return (
                        <div
                            key={el.id}
                            className={classes(styles.TableRow, {
                                [styles.Active]: selectedFolderId === el.id,
                            })}
                            onDoubleClick={() => {
                                if (el.type === 'FOLDER' && !isLeaf(el)) {
                                    dispatch(
                                        pushFolderToPath({
                                            title: el.title,
                                            url: el.id,
                                        })
                                    );
                                    onFolderChange(el.id);
                                }
                            }}
                            onClick={() => {
                                if (isLeaf(el) && !isEmpty(el)) {
                                    setSelectedFolderId(el.id);
                                }
                            }}
                        >
                            <div className={styles.Name}>
                                {el.type === 'FILE' ? (
                                    <img
                                        className={styles.Icon}
                                        src={el.preview}
                                        alt={'Folder icon'}
                                    />
                                ) : (
                                    <FolderIcon
                                        fill={el.style.bg_color}
                                        width={23}
                                        height={20}
                                    />
                                )}
                                <div className={styles.Name}>
                                    {decodeURI(
                                        el.type === 'FILE' ? el.name : el.title
                                    )}
                                </div>
                            </div>
                            <div className={styles.Date}>
                                {new Date(
                                    el.type === 'FILE'
                                        ? el.photo.timeCreated ?? 0
                                        : el.updated_at
                                )
                                    .toISOString()
                                    .split('T')[0]
                                    .split('-')
                                    .reverse()
                                    .join('.')}
                            </div>
                            <div className={styles.Info}>
                                {el.type === 'FILE'
                                    ? el.name.substring(
                                          el.name.lastIndexOf('.') + 1
                                      )
                                    : `${el.children.length} файл${
                                          el.children.length > 4 ||
                                          el.children.length === 0 ||
                                          el.children.length === 11 ||
                                          el.children.length === 12 ||
                                          el.children.length === 13 ||
                                          el.children.length === 14
                                              ? 'ов'
                                              : el.children.length != 1
                                              ? 'а'
                                              : ''
                                      }`}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
