import React from 'react';
import style from './ResetPasswordFooter.module.scss';

export default function ResetPasswordFooter(): JSX.Element {
    return (
        <div className={style.footer}>
            <p className={style.footer__privacyPolicyText}>
                Нажимая, вы соглашаетесь с
                <a
                    target="_blank"
                    href="https://dilab.ru/policy/"
                    rel="noreferrer noopener"
                    className={style.footer__privacyPolicyText_accent}
                >
                    {' '}
                    политикой <br /> конфиденциальности
                </a>
            </p>
        </div>
    );
}
