import React from 'react';
import { Link } from 'react-router-dom';
import logo from '@images/logo.svg';
import { ROUTES } from '@constants/routes';
import style from './ResetPasswordHeader.module.scss';

const ResetPasswordHeader = (): JSX.Element => {
    return (
        <div className={style.header}>
            <Link to={ROUTES.LOGIN.END}>
                <img alt="menu logo" src={logo} />
            </Link>
        </div>
    );
};

export default ResetPasswordHeader;
