import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './StorageModal.module.scss';
import { FoldersTable } from './FoldersTable';
import { CustomLoader } from '@components/common/loadingspinner/LoadingSpinner';
import { IArchiveCollection } from '@interfaces/collection.interface';
import { PhotoArchiveApis } from '@APIs/photo-archive.apis';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { createdTimeComparator } from '@components/photoArchive/common/helpers/sorting';
import {
    clearPath,
    selectPath,
    setCompletePath,
    setFolderElements,
} from '@store/feature/folder.slice';
import { ModalBreadcrumbs } from './Breadcrumbs';
import { useAppSelector } from '@hooks/useAppSelector';
import { getPath } from '@components/photoArchive/breadcrumbs/helpers';
import { sortFoldersFirst } from '@components/photoArchive/common/helpers/sorting';
import { classes } from '@utils/classNames';

export const StorageModal = ({
    isOpen,
    closeModal,
    patientId,
    onSelect,
}: {
    isOpen: boolean;
    closeModal: () => void;
    patientId: string;
    onSelect: (id: string) => void;
}): JSX.Element => {
    const [isLoaded, setLoaded] = useState(false);
    const [folders, setFolders] = useState<IArchiveCollection[]>([]);
    const [currentFolder, setCurrentFolder] =
        useState<IArchiveCollection | null>(null);
    const crumbs = useAppSelector(selectPath);
    const [crumbsReady, setCrumbsReady] = useState(false);
    const dispatch = useAppDispatch();
    const [selectedFolderId, setSelectedFolderId] = useState<string | null>(
        null
    );
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (folderId?: string) => {
        if (folderId) {
            PhotoArchiveApis.getFolder(folderId).then((res) => {
                setCurrentFolder(res.data);
                const data = res.data
                    .children as unknown as IArchiveCollection[];
                setFolders(sortFoldersFirst(data));
                setLoaded(true);
                dispatch(setFolderElements(sortFoldersFirst(data)));
                if (crumbs.path.length === 1) {
                    if (res.data.parent !== null) {
                        getPath(res.data.parent, patientId, [
                            { title: res.data.title, url: folderId },
                        ]).then((res) => {
                            res.push({
                                title: 'Хранилище',
                                url: '../photo-collections',
                            });
                            dispatch(setCompletePath(res.reverse()));
                            setCrumbsReady(true);
                        });
                    } else {
                        dispatch(
                            setCompletePath([
                                {
                                    title: 'Хранилище',
                                    url: '../photo-collections',
                                },
                                {
                                    title: res.data.title,
                                    url: res.data.id,
                                },
                            ])
                        );
                        setCrumbsReady(true);
                    }
                } else {
                    setCrumbsReady(true);
                }
            });
        } else {
            PhotoArchiveApis.getFolders(patientId).then((res) => {
                setFolders([...res.data.sort(createdTimeComparator)]);
                setCurrentFolder(null);
                dispatch(clearPath());
                dispatch(setFolderElements(res.data));
                setCrumbsReady(true);
                setLoaded(true);
            });
        }
    };

    const onFolderChange = (id: string) => {
        setLoaded(false);
        fetchData(id);
        setSelectedFolderId(null);
    };

    const goBack = () => currentFolder && onFolderChange(currentFolder?.parent);
    const onSelected = () => {
        if (selectedFolderId) {
            onSelect(selectedFolderId);
            onClose();
        }
    };

    const onClose = () => {
        closeModal();
        setCurrentFolder(null);
        setSelectedFolderId(null);
        fetchData();
    };

    return !isOpen ? (
        <></>
    ) : (
        ReactDOM.createPortal(
            <div className={styles.Background} onClick={onClose}>
                <div
                    className={styles.Widget}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                    <div className={styles.ModalTop}>
                        <div className={styles.ModalTitle}>
                            <span className={styles.Title}>
                                Выберите папку для загрузки фотографий
                            </span>
                            <div
                                className={styles.Close}
                                onClick={onClose}
                            ></div>
                        </div>
                        <ModalBreadcrumbs
                            isReady={crumbsReady}
                            path={crumbs}
                            isRoot={!currentFolder}
                            goBack={goBack}
                        />
                    </div>
                    <div className={styles.ModalBody}>
                        {isLoaded ? (
                            <FoldersTable
                                data={folders}
                                onFolderChange={onFolderChange}
                                setSelectedFolderId={setSelectedFolderId}
                                selectedFolderId={selectedFolderId}
                            />
                        ) : (
                            <CustomLoader />
                        )}
                    </div>
                    <div className={styles.ModalFooter}>
                        <div
                            className={classes(
                                styles.Button,
                                styles.Secondary,
                                {
                                    [styles.Inactive]: !selectedFolderId,
                                }
                            )}
                            onClick={onSelected}
                        >
                            Выбрать
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        )
    );
};
