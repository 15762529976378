import React, { useState } from 'react';
import {
    EmailFormData,
    GetVerificationCodeForm,
} from '@components/resetpassword/body/getVerificationCodeForm/GetVerificationCodeForm';
import { CodeFormData } from '@components/resetpassword/body/checkVerificationCodeForm/CheckVerificationCodeForm';
import {
    SavePasswordForm,
    PasswordFormData,
} from '@components/resetpassword/body/savePasswordForm/SavePasswordForm';
import { CheckVerificationCodeForm } from '@components/resetpassword/body/checkVerificationCodeForm/CheckVerificationCodeForm';
import { useHistory } from 'react-router';
import { ROUTES } from '@constants/routes';
import { AuthorizationApis } from '@APIs/auth.apis';
import SuccessMessage from '@components/resetpassword/body/successMessage/SuccessMessage';

enum ResetPasswordStage {
    GetVerificationCode,
    CheckVerificationCode,
    SaveNewPassword,
    Success,
}

const ResetPasswordBody = (): JSX.Element => {
    const [stage, setStage] = useState<ResetPasswordStage>(
        ResetPasswordStage.GetVerificationCode
    );
    const [email, setEmail] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const history = useHistory();

    const onSendVerificationCode = async (formData: EmailFormData) => {
        setIsLoading(true);
        try {
            const res = await AuthorizationApis.sendResetVerificationCode(
                formData.email
            );
            if (res.status === 200) {
                setEmail(formData.email);
                setStage(ResetPasswordStage.CheckVerificationCode);
                setError('');
            }
        } catch (e) {
            setError('Пользователь с таким email не найден');
        } finally {
            setIsLoading(false);
        }
    };

    const onCheckVerificationCode = async (formData: CodeFormData) => {
        setIsLoading(true);
        try {
            const res = await AuthorizationApis.checkVerificationCode(
                email as string,
                formData.code
            );

            switch (res.data.message) {
                case 'code accepted':
                    setStage(ResetPasswordStage.SaveNewPassword);
                    setError('');
                    break;
                default:
                    switch (res.data.error) {
                        case 'wrong_code':
                            setError('Неверный код');
                            break;
                        case 'out_of_attempts':
                            setError(
                                'Достигнут лимит ввода. Пожалуйста, запросите новый код.'
                            );
                            break;
                        case 'expired':
                            setError(
                                'Код истек. Пожалуйста, запросите новый код.'
                            );
                            break;
                        default:
                            setError(
                                'Произошла ошибка, повторите попытку позже'
                            );
                            break;
                    }
                    break;
            }
        } catch {
            setError('Произошла ошибка, повторите попытку позже');
        } finally {
            setIsLoading(false);
        }
    };

    const onSaveNewPassword = async (formData: PasswordFormData) => {
        setIsLoading(true);
        try {
            const res = await AuthorizationApis.saveNewPassword(
                email as string,
                formData.password
            );
            if (res.data.message) {
                setStage(ResetPasswordStage.Success);
            }
        } catch (e) {
            setError('Произошла ошибка, повторите попытку позже');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {stage === ResetPasswordStage.GetVerificationCode && (
                <GetVerificationCodeForm
                    onSubmit={onSendVerificationCode}
                    error={error}
                    isLoading={isLoading}
                />
            )}
            {stage === ResetPasswordStage.CheckVerificationCode && (
                <CheckVerificationCodeForm
                    onSubmit={onCheckVerificationCode}
                    error={error}
                    isLoading={isLoading}
                    email={email as string}
                />
            )}
            {stage === ResetPasswordStage.SaveNewPassword && (
                <SavePasswordForm
                    onSubmit={onSaveNewPassword}
                    error={error}
                    isLoading={isLoading}
                />
            )}
            {stage === ResetPasswordStage.Success && (
                <SuccessMessage
                    onSuccessReset={() => history.push(ROUTES.LOGIN.END)}
                />
            )}
        </div>
    );
};

export default ResetPasswordBody;
