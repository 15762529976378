import { IPresentation } from '@interfaces/presentation.interface';
import { id } from '@utils/utils';
import { IPatient } from '@interfaces/patient.interface';
import { ITrg } from '@interfaces/trg.interface';

export const presentationsFake: IPresentation[] = [
    {
        id: id(),
        name: 'Консультация',
        description: 'Описание презентации',
        preview: '',
        state: 'IN_PROCESS',
        date: 1635640000000,
        keywords: ['консультация', 'презентация'],
        patient: {
            id: id(),
            name: 'Богдан',
            surname: 'Кривицкий',
            middleName: 'Михайлович',
            dateOfBirth: 1635640000000,
            sex: 'male',
            avatar: null,
            createDate: 1635640000000,
            'patient-notify': false,
        },
        pages: 5,
        link: '',
        collectionKey: id(),
        patientId: id(),
        importFonts: [],
        collection: {
            id: id(),
            title: 'Коллекция Dilab',
        },
    },
    {
        id: id(),
        name: 'Диагностика',
        description: 'Описание презентации',
        preview: '',
        state: 'IN_PROCESS',
        date: 1635640000000,
        keywords: ['консультация', 'презентация'],
        patient: {
            id: id(),
            name: 'Валентин',
            surname: 'Иванов',
            middleName: 'Викторович',
            dateOfBirth: 1635640000000,
            sex: 'male',
            avatar: null,
            createDate: 1635640000000,
            'patient-notify': false,
        },
        pages: 5,
        link: '',
        collectionKey: id(),
        patientId: id(),
        importFonts: [],
        collection: {
            id: id(),
            title: 'Коллекция Dilab',
        },
    },
];

export const patientsFake: IPatient[] = [
    {
        id: id(),
        name: 'Валентин',
        surname: 'Иванов',
        middleName: 'Викторович',
        dateOfBirth: 1635640000000,
        sex: 'male',
        avatar: null,
        createDate: 1635640000000,
        'patient-notify': false,
    },
    {
        id: id(),
        name: 'Богдан',
        surname: 'Кривицкий',
        middleName: 'Михайлович',
        dateOfBirth: 1635640000000,
        sex: 'male',
        avatar: null,
        createDate: 1635640000000,
        'patient-notify': false,
    },
];

export const TrgFake: ITrg[] = [
    {
        state: 'IN_PROCESS',
        id: id(),
        photo: '',
        bgColor: '#FFFFFF',
        title: 'Название ТРГ',
        description: 'Описание ТРГ',
        patient: patientsFake[0],
        ruler: null,
        trace: {
            id: id(),
        },
        points: {
            m0: {
                x: 0,
                y: 0,
                is_active: false,
                description: '',
                image: '',
            },
        },
        ratio: {
            is_completed: true,
            value: 150,
            m0: {
                x: 0,
                y: 0,
            },
            m1: {
                x: 0,
                y: 0,
            },
        },
        results: [
            {
                method: 'ANGLE',
                value: 50,
                match: 'EQUAL',
                description: '',
                created_at: 1635640000000,
                updated_at: 1635640000000,
            },
        ],
        created_at: 1635640000000,
        updated_at: 1635640000000,
    },
    {
        state: 'COMPLETED',
        id: id(),
        photo: '',
        bgColor: '#FFFFFF',
        title: 'Название ТРГ',
        description: 'Описание ТРГ',
        patient: patientsFake[1],
        ruler: null,
        trace: {
            id: id(),
        },
        points: {
            m0: {
                x: 0,
                y: 0,
                is_active: false,
                description: '',
                image: '',
            },
        },
        ratio: {
            is_completed: true,
            value: 150,
            m0: {
                x: 0,
                y: 0,
            },
            m1: {
                x: 0,
                y: 0,
            },
        },
        results: [
            {
                method: 'ANGLE',
                value: 50,
                match: 'EQUAL',
                description: '',
                created_at: 1635640000000,
                updated_at: 1635640000000,
            },
        ],
        created_at: 1635640000000,
        updated_at: 1635640000000,
    },
];
