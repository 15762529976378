/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function useDebounce(func: any, delay: number) {
    let timeout: NodeJS.Timeout | null = null;

    return function (this: any, ...argc: any[]) {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            func.apply(this, argc);
        }, delay);
    };
}
