import React, { useMemo } from 'react';
import { classes } from '@utils/classNames';
import style from './ModalListItem.module.scss';
import { ReactComponent as Star } from '@images/star.svg';

const ModalListItem = ({
    text,
    onClick,
    isSelected,
    isMarked = false,
    date,
}: {
    text: string;
    onClick: () => void;
    isSelected: boolean;
    isMarked?: boolean;
    date?: number;
}) => {
    const formattedDate = useMemo(() => {
        if (date) {
            return new Date(date).toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
            });
        }
    }, [date]);
    return (
        <div
            className={classes(style.collectionItem, {
                [style.collectionItem_selected]: isSelected,
            })}
            onClick={onClick}
        >
            <span className={style.collectionItem__title}>{text}</span>
            {!!date && (
                <span className={style.collectionItem__date}>
                    {formattedDate}
                </span>
            )}
            {isMarked && <Star />}
        </div>
    );
};

export default ModalListItem;
